import React, { useRef } from 'react';
import "./navbar.css";
import { useAppContext } from '../../context';

const Navbar = () => {
    const { setNavOpen, navOpen, setBtnHide, btnHide } = useAppContext();
    const navRef = useRef();
    const showNavBar = () => {
        navRef.current.classList.toggle("responsive-nav");
        setBtnHide(!btnHide);
        setNavOpen(!navOpen);
    }

    return (
        <header>
            <div className='logo'>
                <a href='/'>
                    <img className='img-logo' src='/images/logo2.png' alt='logo'></img>
                </a>
            </div>
            <nav ref={navRef}>
                <a href='/'>Home</a>
                <a href='#services'>Servizi</a>
                <a href='#about'>Chi siamo</a>
                <a href='#contacts'>Contattaci</a>
                <a href='/jobs'>Lavora con noi</a>
                <button alt="close-button" className='nav-btn nav-close-btn' onClick={showNavBar}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>

                </button>
            </nav>
            <button alt="menu-toggle" id='burger-menu' className='nav-btn' onClick={showNavBar} aria-label='Open the menu'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>

            </button>
        </header>
    );
}

export default Navbar;
